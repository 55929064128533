import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-10" }

import Textbox from "@/common/components/Textbox.vue";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import Start from "@/home/views/Start.vue";
import TextButton from "@/common/components/TextButton.vue";
import { BackendAuthProvider } from "@/common/auth/backendAuthProvider";
import { useBackendAuth } from "@/common/auth/backendAuth";
import { useAuthProvider } from "@/common/auth/authStore";
import { isEmpty } from "lodash";


export default /*@__PURE__*/_defineComponent({
  __name: 'ResetPasswordPage',
  setup(__props) {

const backendAuth = useBackendAuth();
const backendProvider = useAuthProvider();
const connecting = ref(false);

const mode = ref<"email" | "check">("email");
const email = ref("");

const router = useRouter();

function goToLogin() {
  router.push("/login");
}

async function resetPassword() {
  connecting.value = true;
  try {
    if (!(backendProvider instanceof BackendAuthProvider)) {
      throw new Error("Login page required backend auth provider");
    }
    if (isEmpty(email.value)) {
      return;
    }
    // Send login request to backend
    await backendAuth.resetPassword(email.value);
    mode.value = "check";
  } finally {
    connecting.value = false;
  }
}

const formValidated = computed(() => {
  if (email.value.trim().length <= 3 || !email.value.includes("@")) {
    return false;
  }
  return true;
});

return (_ctx: any,_cache: any) => {
  const _component_SpinnerButton = _resolveComponent("SpinnerButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (mode.value === 'email')
      ? (_openBlock(), _createBlock(Start, {
          key: 0,
          icon: "md:lock_reset",
          title: "Reset password"
        }, {
          footer: _withCtx(() => [
            (!connecting.value)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(TextButton, {
                    label: "Back",
                    secondary: true,
                    onClick: goToLogin
                  }),
                  _createVNode(TextButton, {
                    label: "Reset password",
                    onClick: resetPassword,
                    disabled: !formValidated.value
                  }, null, 8, ["disabled"])
                ], 64))
              : (_openBlock(), _createBlock(_component_SpinnerButton, {
                  key: 1,
                  label: "Resetting password…"
                }))
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-lg text-white" }, " Enter the email address of your claritype user account and we’ll send you a link to reset your password. ", -1)),
              _createElementVNode("div", null, [
                _createVNode(Textbox, {
                  label: "Email",
                  type: "email",
                  autocomplete: "email",
                  id: "email-field",
                  modelValue: email.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event))
                }, null, 8, ["modelValue"])
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (mode.value === 'check')
      ? (_openBlock(), _createBlock(Start, {
          key: 1,
          icon: "md:mark_email_read",
          title: "Email sent"
        }, {
          footer: _withCtx(() => [
            _createVNode(TextButton, {
              label: "Done",
              onClick: goToLogin
            })
          ]),
          default: _withCtx(() => [
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-col gap-10" }, [
              _createElementVNode("div", { class: "text-lg text-white" }, "Check your inbox for next steps.")
            ], -1))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})