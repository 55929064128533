import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "min-h-[46px]" }
const _hoisted_2 = {
  key: 0,
  class: "flex-start flex flex-row gap-5 bg-gray-800 p-10"
}
const _hoisted_3 = {
  key: 0,
  class: "flex flex-row flex-wrap gap-15 p-15"
}
const _hoisted_4 = {
  key: 0,
  class: "text-lg"
}
const _hoisted_5 = {
  key: 1,
  class: "text-lg"
}

import { Async, AsyncStatus } from "@/common/lib/async";
import AsyncWrapper from "@/common/components/AsyncWrapper.vue";
import { ModuleMetadata } from "@/common/stores/userModuleStore";
import Module from "./Module.vue";
import { computed, ref } from "vue";
import TextButton from "@/common/components/TextButton.vue";
import pluralize from "pluralize";
import Dialog from "@/common/components/Dialog.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Modules',
  props: {
    modules: {},
    filtered: { type: Boolean },
    allowDeletes: { type: Boolean }
  },
  emits: ["openModule", "deleteModule"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const deletingModules = ref(false);

const selectedModules = computed(() => {
  if (props.modules.status !== AsyncStatus.Succeeded) {
    return [];
  }
  return props.modules.result.filter((m) => m.selected);
});

function deselectModules() {
  if (props.modules.status !== AsyncStatus.Succeeded) {
    return [];
  }
  props.modules.result.forEach((m) => (m.selected = false));
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(AsyncWrapper, {
      async: _ctx.modules,
      overlay: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (selectedModules.value.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_ctx.allowDeletes)
                  ? (_openBlock(), _createBlock(TextButton, {
                      key: 0,
                      label: `Delete ${selectedModules.value.length} ${_unref(pluralize)(
            'project',
            selectedModules.value.length
          )}`,
                      icon: "md:delete",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (deletingModules.value = true))
                    }, null, 8, ["label"]))
                  : _createCommentVNode("", true),
                _createVNode(TextButton, {
                  label: "Clear selection",
                  icon: "md:cancel",
                  onClick: deselectModules
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.modules.status === _unref(AsyncStatus).Succeeded)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modules.result, (m) => {
                return (_openBlock(), _createBlock(Module, {
                  key: m.id,
                  module: m,
                  onOpenModule: ($event: any) => (emit('openModule', m)),
                  onDeleteModule: ($event: any) => (emit('deleteModule', [m]))
                }, null, 8, ["module", "onOpenModule", "onDeleteModule"]))
              }), 128)),
              (_ctx.modules.result.length === 0)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (_ctx.filtered)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, "No matching projects found"))
                      : (_openBlock(), _createElementBlock("div", _hoisted_5, "No projects found"))
                  ], 64))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["async"]),
    (deletingModules.value)
      ? (_openBlock(), _createBlock(Dialog, {
          key: 0,
          title: "Delete projects?",
          "success-label": "Delete",
          onSucceeded: _cache[1] || (_cache[1] = ($event: any) => {
      emit('deleteModule', selectedModules.value);
      deletingModules.value = false;
    }),
          onCancelled: _cache[2] || (_cache[2] = ($event: any) => (deletingModules.value = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Are you sure you want to delete " + _toDisplayString(selectedModules.value.length) + " " + _toDisplayString(_unref(pluralize)("project", selectedModules.value.length)) + "? This cannot be undone. ", 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})