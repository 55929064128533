import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "main" }
const _hoisted_2 = { id: "content" }

import { loadHubspotTracking } from "@/common/lib/hubspot";
import FailureDialog from "@/common/components/FailureDialog.vue";
import { onMounted, provide, ref } from "vue";
import { DarkMode } from "@/common/lib/keys";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const darkMode = ref(true);
provide(DarkMode, darkMode);

onMounted(() => {
  if (darkMode.value) document.querySelector("body")?.classList.add("dark");
  loadHubspotTracking();
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view)
      ])
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", { id: "modal-container" }, null, -1)),
    _createVNode(FailureDialog)
  ], 64))
}
}

})