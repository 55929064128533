import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-10" }
const _hoisted_2 = {
  key: 0,
  class: "text-lg text-gray-200"
}
const _hoisted_3 = {
  key: 1,
  class: "text-lg text-gray-200"
}

import Textbox from "@/common/components/Textbox.vue";
import Start from "@/home/views/Start.vue";
import { computed, ref } from "vue";
import { isEmpty } from "lodash";
import { useBackendAuth } from "@/common/auth/backendAuth";
import { useAuthProvider } from "@/common/auth/authStore";
import { BackendAuthProvider } from "@/common/auth/backendAuthProvider";
import { environment } from "@/common/environments/environmentLoader";
import TextButton from "@/common/components/TextButton.vue";
import SpinnerButton from "@/common/components/SpinnerButton.vue";
import { useKeyHandler } from "@/common/composables/useKeyHandler";
import { KeyCommand } from "@/common/lib/modifierKeys";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginPage',
  setup(__props) {

const email = ref("");
const password = ref("");

const backendAuth = useBackendAuth();
const backendProvider = useAuthProvider();
const connecting = ref(false);

async function tryLogin() {
  connecting.value = true;
  try {
    if (!(backendProvider instanceof BackendAuthProvider)) {
      throw new Error("Login page required backend auth provider");
    }
    if (isEmpty(email.value) || isEmpty(password.value)) {
      return;
    }
    // Send login request to backend
    const response = await backendAuth.login(email.value, password.value);
    if (response.success) {
      backendProvider.finishLogin({
        accessToken: response.response.auth.access_token,
        refreshToken: response.response.auth.refresh_token,
        user: response.response.user,
      });
    } else {
      connecting.value = false;
      passwordError.value = response.error;
    }
  } catch (error: unknown) {
    connecting.value = false;
  }
}

const showSignupLink = computed(() => environment.requireBoolean("AUTH_BACKEND_SIGNUP"));
const showResetPasswordLink = computed(() =>
  environment.requireBoolean("AUTH_BACKEND_RESET_PASSWORD")
);

const emailError = ref<string | undefined>(undefined);
function validateEmail() {
  if (email.value.trim().length <= 3 || !email.value.includes("@")) {
    return false;
  }
  return true;
}

function blurEmail() {
  if (validateEmail()) {
    emailError.value = undefined;
  } else {
    emailError.value = "Valid email required";
  }
}

const passwordError = ref<string | undefined>(undefined);
function validatePassword() {
  if (password.value.length === 0) {
    return false;
  }
  return true;
}

const keyHandler = useKeyHandler();
keyHandler.registerHandler(new KeyCommand("Enter"), handleEnterKey, true, false);

function blurPassword() {
  if (validatePassword()) {
    passwordError.value = undefined;
  } else {
    passwordError.value = "Password required";
  }
}

function handleEnterKey() {
  if (!formValidated.value) {
    return;
  }
  return tryLogin();
}

const formValidated = computed(() => {
  if (!validateEmail()) {
    return false;
  }
  if (!validatePassword()) {
    return false;
  }
  return true;
});

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createBlock(Start, { title: "Welcome" }, {
    footer: _withCtx(() => [
      (!connecting.value)
        ? (_openBlock(), _createBlock(TextButton, {
            key: 0,
            label: "Log in",
            disabled: !formValidated.value,
            onClick: _cache[4] || (_cache[4] = ($event: any) => (tryLogin()))
          }, null, 8, ["disabled"]))
        : (_openBlock(), _createBlock(SpinnerButton, {
            key: 1,
            label: "Logging in"
          }))
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(Textbox, {
            label: "Email",
            modelValue: email.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
            autocomplete: "email",
            id: "email-field",
            disabled: connecting.value,
            "error-text": emailError.value ?? '',
            onFocus: _cache[1] || (_cache[1] = ($event: any) => (emailError.value = '')),
            onBlur: blurEmail
          }, null, 8, ["modelValue", "disabled", "error-text"]),
          _createVNode(Textbox, {
            label: "Password",
            modelValue: password.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((password).value = $event)),
            type: "password",
            autocomplete: "current-password",
            id: "password-field",
            disabled: connecting.value,
            onFocus: _cache[3] || (_cache[3] = ($event: any) => (passwordError.value = '')),
            onBlur: blurPassword,
            "error-text": passwordError.value ?? ''
          }, null, 8, ["modelValue", "disabled", "error-text"])
        ]),
        (showResetPasswordLink.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_RouterLink, {
                to: "/reset-password",
                class: "text-orange"
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("Forgot your password?")
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        (showSignupLink.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _cache[7] || (_cache[7] = _createTextVNode(" Don't have a claritype account? ")),
              _createVNode(_component_RouterLink, {
                to: "/signup",
                class: "text-orange"
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("Let's get you set up.")
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}
}

})