import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useAuthProvider } from "@/common/auth/authStore";
import Start from "@/home/views/Start.vue";
import TextButton from "@/common/components/TextButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Logout',
  setup(__props) {

const authProvider = useAuthProvider();

async function goHome() {
  await authProvider.loginWithRedirect();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(Start, { title: "Logged out" }, {
    footer: _withCtx(() => [
      _createVNode(TextButton, {
        label: "Log back in",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (goHome()))
      })
    ]),
    default: _withCtx(() => [
      _cache[1] || (_cache[1] = _createElementVNode("div", null, "You have logged out.", -1))
    ]),
    _: 1
  }))
}
}

})