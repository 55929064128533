import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["data-test"]
const _hoisted_2 = { class: "cursor-pointer rounded-[5px] border-[1px] border-solid border-gray-700" }
const _hoisted_3 = { class: "flex justify-center" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "svg-to-dark-gray h-[200px] w-[248px] object-contain p-20",
  src: "/images/icons/claritype.svg"
}
const _hoisted_6 = { class: "flex flex-col flex-nowrap justify-between px-2" }
const _hoisted_7 = { class: "w-100 flex flex-row items-center justify-between" }
const _hoisted_8 = { class: "py-[16px] text-lg font-bold" }
const _hoisted_9 = { class: "flex flex-col gap-4" }
const _hoisted_10 = { class: "flex flex-row flex-wrap justify-between text-gray-500" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { class: "flex flex-row flex-wrap justify-between text-gray-500" }
const _hoisted_13 = { key: 0 }

import Dialog from "@/common/components/Dialog.vue";
import IconButton from "@/common/components/IconButtonV2.vue";
import Menu from "@/common/components/Menu.vue";
import Textbox from "@/common/components/Textbox.vue";
import { useNavigation } from "@/common/composables/useNavigation";
import { environment } from "@/common/environments/environmentLoader";
import { useWorkspaceStore } from "@/common/stores/workspaceStore";
import {
  moduleHasType,
  ModuleMetadata,
  ModuleType,
  useUserModuleStore,
} from "@/common/stores/userModuleStore";
import { Dropdown, Tooltip } from "floating-vue";
import { DateTime } from "luxon";
import { computed, onMounted, ref, toRefs } from "vue";
import { useUserStore } from "@/common/stores/userStore";
import { storeToRefs } from "pinia";

export default /*@__PURE__*/_defineComponent({
  __name: 'Module',
  props: {
    module: {}
  },
  emits: ["openModule", "deleteModule"],
  setup(__props: any, { emit: __emit }) {

const userStore = useUserStore();
const { globalAdmin } = storeToRefs(userStore);

const {
  duplicateModule,
  renameModule,
  publishModule,
  publishLocalModule,
  publishReaderView,
  unshareReaderView,
} = useUserModuleStore();
const props = __props;
const { module } = toRefs(props);
const emit = __emit;

const renamingModule = ref<string | undefined>();
const duplicateProject = ref<string | undefined>();
const deletingModule = ref(false);
const assigningTag = ref(false);
const tagName = ref("");

const menuItems = computed(() => {
  const items = [];
  if (module.value.moduleType === ModuleType.User) {
    items.push({
      key: "rename",
      label: "Rename",
      icon: "md:text_select_start",
      action: () => (renamingModule.value = module.value.name),
    });
    if (module.value.databricks_host) {
      items.push({
        key: "duplicate",
        label: "Duplicate",
        icon: "md:file_copy",
        action: () => (duplicateProject.value = module.value.name),
      });
    }
  }
  if (module.value.moduleType === ModuleType.User && module.value.databricks_host) {
    if (environment.requireBoolean("ENABLE_PUBLISHING")) {
      items.push({
        key: "publish",
        label: "Publish module",
        icon: "md:publish",
        action: () => publishModule(module.value.id),
      });
    }
    if (environment.requireBoolean("READER_VIEW_PUBLISHING_ENABLED")) {
      const readerPublished = module.value.published_reader_view;
      items.push({
        key: "publish_reader",
        label: readerPublished ? "Unshare Reader view" : "Share Reader view",
        icon: "md:bar_chart",
        action: () => toggleReaderView(readerPublished),
      });
      items.push({
        key: "tag_project",
        label: "Tag project",
        icon: "md:sell",
        action: () => (assigningTag.value = true),
      });
    }
    if (environment.requireBoolean("LOCAL_EXPORTS")) {
      items.push({
        key: "export-local",
        label: "Export locally",
        icon: "download",
        action: () => publishLocalModule(module.value.id),
      });
    }
    if (environment.requireBoolean("VIEW_IN_EXPLORER")) {
      items.push({
        key: "view-in-explorer",
        label: "View in Reader",
        icon: "explore-outline",
        action: () => useNavigation().openUrl(getReaderUrl()),
      });
    }
  }
  if (isDeletable.value) {
    items.push({
      key: "delete",
      label: "Delete",
      icon: "md:delete",
      action: () => (deletingModule.value = true),
    });
  }

  if (isUnshareable) {
    items.push({
      key: "unshare_reader",
      label: "Unshare Reader view",
      icon: "md:bar_chart",
      action: () => unshareReaderView(module.value.id),
    });
  }
  return items;
});

const updatedDate = computed(() => formatDate(module.value.updated));

function formatDate(date: DateTime): string | null {
  return date.toRelative();
}

const isDeletable = computed(() => {
  if (module.value.moduleType === ModuleType.User) {
    return true;
  }
  if (moduleHasType(module.value, ModuleType.Published)) {
    if (module.value.owned) {
      return true;
    }
    if (globalAdmin.value) {
      return true;
    }
  }
  return false;
});

const showPublishedBy = moduleHasType(
  module.value,
  ModuleType.Published,
  ModuleType.Local,
  ModuleType.Reader
);

const isUnshareable = module.value.moduleType === ModuleType.Reader && globalAdmin.value;

async function finishRenameModule() {
  if (!renamingModule.value) {
    return;
  }
  await renameModule(module.value.id, module.value.name, renamingModule.value);
  renamingModule.value = undefined;
}

async function finishDuplicatingProject() {
  if (!duplicateProject.value) {
    return;
  }
  await duplicateModule(module.value.id, duplicateProject.value);
}

function toggleSelected() {
  module.value.selected = !module.value.selected;
}

async function toggleReaderView(readerPublished: boolean | null) {
  await publishReaderView(module.value.id, readerPublished ?? false);
  // TODO: add to return value
  module.value.published_reader_view = !readerPublished;
}

async function setModuleTag() {
  if (tagName.value.trim().length === 0) {
    return;
  }
  await useUserModuleStore().tagModule(tagName.value, module.value.id);
  module.value.tag = tagName.value;
  tagName.value = "";
  assigningTag.value = false;
}

function getReaderUrl() {
  const moduleId = module.value.tag ?? module.value.id;
  return `/reader/modules/${moduleId}/explore`;
}

onMounted(() => useWorkspaceStore().initializeWorkspaceState());

return (_ctx: any,_cache: any) => {
  const _directive_close_popper = _resolveDirective("close-popper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["m-5 w-[290px] rounded-[5px] border-[1px] p-5 hover:border-orange", {
      'border-orange': _unref(module).selected,
      'border-transparent': !_unref(module).selected,
    }])
    }, [
      _createElementVNode("div", {
        onClick: [
          _cache[0] || (_cache[0] = _withModifiers(($event: any) => (emit('openModule', _unref(module))), ["exact"])),
          _withModifiers(toggleSelected, ["shift","stop"])
        ],
        "data-test": `module-${_unref(module).name}`
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_unref(module).thumbnail)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: "thumbnail h-[200px] w-[248px] object-contain",
                  src: _unref(module).thumbnail
                }, null, 8, _hoisted_4))
              : (_openBlock(), _createElementBlock("img", _hoisted_5))
          ])
        ])
      ], 8, _hoisted_1),
      _createElementVNode("span", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(module).name), 1),
          (menuItems.value.length > 0)
            ? (_openBlock(), _createBlock(_unref(Dropdown), { key: 0 }, {
                popper: _withCtx(() => [
                  _withDirectives(_createVNode(Menu, { items: menuItems.value }, null, 8, ["items"]), [
                    [_directive_close_popper]
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode(_unref(Tooltip), { delay: { show: 1000, hide: 100 } }, {
                    popper: _withCtx(() => [
                      _createTextVNode("Manage " + _toDisplayString(_unref(module).name), 1)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(IconButton, {
                        name: "md:more_vert",
                        size: "xl",
                        "dark-hover": true
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            (updatedDate.value)
              ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(updatedDate.value), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_12, [
            (_unref(module).owner_name && _unref(showPublishedBy))
              ? (_openBlock(), _createElementBlock("span", _hoisted_13, " Published by " + _toDisplayString(_unref(module).owner_name), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ], 2),
    (renamingModule.value !== undefined)
      ? (_openBlock(), _createBlock(Dialog, {
          key: 0,
          title: "Rename project",
          "success-label": "Rename",
          onSucceeded: finishRenameModule,
          onCancelled: _cache[2] || (_cache[2] = ($event: any) => (renamingModule.value = undefined))
        }, {
          default: _withCtx(() => [
            _createVNode(Textbox, {
              label: "Project Name",
              modelValue: renamingModule.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((renamingModule).value = $event)),
              autoselect: true
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (duplicateProject.value !== undefined)
      ? (_openBlock(), _createBlock(Dialog, {
          key: 1,
          title: "Duplicate project",
          "success-label": "Duplicate",
          onSucceeded: finishDuplicatingProject,
          onCancelled: _cache[4] || (_cache[4] = ($event: any) => (duplicateProject.value = undefined))
        }, {
          default: _withCtx(() => [
            _createVNode(Textbox, {
              label: "New Project Name",
              modelValue: duplicateProject.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((duplicateProject).value = $event)),
              autoselect: true
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (deletingModule.value)
      ? (_openBlock(), _createBlock(Dialog, {
          key: 2,
          title: "Delete project?",
          "success-label": "Delete",
          onSucceeded: _cache[5] || (_cache[5] = ($event: any) => (emit('deleteModule'))),
          onCancelled: _cache[6] || (_cache[6] = ($event: any) => (deletingModule.value = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Are you sure you want to delete project " + _toDisplayString(_unref(module).name) + "? This cannot be undone. ", 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (assigningTag.value)
      ? (_openBlock(), _createBlock(Dialog, {
          key: 3,
          title: "Tag project",
          icon: "md:sell",
          "success-label": "Tag",
          onSucceeded: _cache[8] || (_cache[8] = () => setModuleTag()),
          onCancelled: _cache[9] || (_cache[9] = ($event: any) => (assigningTag.value = false))
        }, {
          default: _withCtx(() => [
            _createVNode(Textbox, {
              label: "Tag",
              modelValue: tagName.value,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((tagName).value = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})