import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, isRef as _isRef, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "content body" }
const _hoisted_2 = { class: "user-line" }
const _hoisted_3 = {
  key: 0,
  class: "modules-container subpage-container"
}
const _hoisted_4 = { class: "scrollable" }
const _hoisted_5 = {
  key: 1,
  class: "subpage-container"
}

import MenuListItem from "@/common/components/MenuListItem.vue";
import SourceBrowser from "@/common/components/SourceBrowser.vue";
import TextButton from "@/common/components/TextButton.vue";
import Textbox from "@/common/components/Textbox.vue";
import { useNavigation } from "@/common/composables/useNavigation";
import { environment } from "@/common/environments/environmentLoader";
import { AsyncStatus, asyncSucceeded } from "@/common/lib/async";
import { MapAction } from "@/common/lib/mapActions";
import { useWorkspaceStore, WorkspacesState } from "@/common/stores/workspaceStore";
import { SourceSelection } from "@/common/stores/sourceBrowser";
import { useSettingsStore, Routes, SelectedTab, SubPage } from "@/common/stores/settingsStore";
import UserButton from "@/home/components/user-home/HomeUserButton.vue";
import Modules from "@/home/components/user-home/Modules.vue";
import { ModuleMetadata, ModuleType, useUserModuleStore } from "@/common/stores/userModuleStore";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, watch } from "vue";
import HomeTitleBar from "../components/user-home/HomeTitleBar.vue";
import { capitalize, isEmpty, uniq } from "lodash";
import { useResizeListener } from "@/common/composables/useResizeListener";
import { useRouter } from "vue-router";
import { useKeyHandler } from "@/common/composables/useKeyHandler";
import { KeyCommand } from "@/common/lib/modifierKeys";
import { useUserStore } from "@/common/stores/userStore";
import UserPage from "@/common/components/UserPage.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserHome',
  setup(__props) {

const userModuleStore = useUserModuleStore();
const navigation = useNavigation();
const settingStore = useSettingsStore();
const workspaceStore = useWorkspaceStore();
const router = useRouter();

const { workspacesState } = storeToRefs(workspaceStore);
const { subPage, userSelected } = storeToRefs(settingStore);
const { currentModuleType } = storeToRefs(userModuleStore);
const { globalAdmin } = storeToRefs(useUserStore());

const activeFilter = computed(() => currentModuleType.value);
const sourceBrowserExpanded = ref(true);
const projectSearchRef = ref("");
const parentDiv = ref<HTMLElement | null>(null);
const divHeight = ref(0);

const calculateDivHeight = () => {
  divHeight.value = parentDiv.value?.offsetHeight ?? 0;
};

const modules = computed(() => {
  const modules = userModuleStore.modules;
  if (modules.status !== AsyncStatus.Succeeded) {
    return modules;
  }
  return asyncSucceeded(
    modules.result.filter((m) => {
      const searchTerm = projectSearchRef.value.toLowerCase();
      return (
        m.name.toLowerCase().includes(searchTerm) ||
        (activeFilter.value === ModuleType.Published &&
          m.owner_name?.toLowerCase().includes(searchTerm))
      );
    })
  );
});

const searchProjectsBox = ref<typeof Textbox | undefined>(undefined);
const findKeyCommand = KeyCommand.readKeyCommand(
  environment.require("KEY_COMMAND_HOME_SEARCH_PROJECTS")
);
useKeyHandler().registerHandler(
  findKeyCommand,
  () => searchProjectsBox.value?.focus(),
  true,
  false
);

watch(userSelected, (newValue) => {
  if (newValue === false) {
    router.replace({ name: Routes.Home });
  } else {
    router.replace({ name: settingStore.selectedTab });
  }
});

function setModuleType(moduleType: ModuleType) {
  userSelected.value = false;
  userModuleStore.setModuleType(moduleType);
}

async function sourceBrowserTablesSelected(selections: SourceSelection[]) {
  const action = new MapAction({});
  selections.forEach((s) => action.addDbxTable(s.table));
  const moduleId = await userModuleStore.newModule();
  let name = undefined;
  if (environment.requireBoolean("USE_TABLE_NAME_AS_PROJECT_NAME")) {
    name = autogenerateProjectName(selections);
  }
  await userModuleStore.updateModule(moduleId, { name, map: action.map });
  navigation.openProject(moduleId, true);
}

async function newProject() {
  const moduleId = await userModuleStore.newModule();
  await userModuleStore.updateModule(moduleId, { map: { in_connections: {} } });
  navigation.openProject(moduleId, true);
}

async function openModule(module: ModuleMetadata) {
  switch (activeFilter.value) {
    case ModuleType.User: {
      navigation.openProject(module.id);
      break;
    }
    case ModuleType.Published: {
      // Load published module
      const copyId = await userModuleStore.duplicatePublishedModule(module.id);
      navigation.openProject(copyId);
      break;
    }
    case ModuleType.Local: {
      // Load local module
      const copyId = await userModuleStore.duplicateLocalModule(module.id);
      navigation.openProject(copyId);
      break;
    }
    case ModuleType.Reader: {
      const moduleId = module.tag ?? module.id;
      navigation.goToUrl(`/reader/modules/${moduleId}/explore`);
    }
  }
}

const allowDeletes = computed(() => {
  return (
    activeFilter.value === ModuleType.User ||
    (activeFilter.value !== ModuleType.Local && globalAdmin.value)
  );
});

async function deleteModule(modules: ModuleMetadata[]) {
  switch (activeFilter.value) {
    case ModuleType.User: {
      await Promise.all(modules.map((m) => userModuleStore.deleteModule(m.id)));
      break;
    }
    case ModuleType.Published: {
      await Promise.all(modules.map((m) => userModuleStore.deletePublishedModule(m.id)));
      await userModuleStore.loadPublished();
      break;
    }
  }
}

function showFilter(moduleType: ModuleType): boolean {
  if (userSelected.value) {
    return false;
  }
  return activeFilter.value === moduleType;
}

function adjustColumnWidth(expanded: boolean) {
  sourceBrowserExpanded.value = expanded;
}

function autogenerateProjectName(selections: SourceSelection[]): string | undefined {
  if (isEmpty(selections)) {
    return undefined;
  }
  if (selections.length > 1) {
    // Try schema name if multiple selections are all in the same schema
    const schemas = uniq(selections.map((sel) => sel.table.schema));
    if (schemas.length === 1) {
      return prettifyTableName(schemas[0]);
    }
  }
  return prettifyTableName(selections[0].table.name);
}

function prettifyTableName(tableName: string): string {
  return tableName.replaceAll(/[_-]/g, " ").split(" ").map(capitalize).join(" ");
}

useResizeListener(calculateDivHeight);

onMounted(() => {
  calculateDivHeight();
});

watch(
  () => workspaceStore.currentWorkspace,
  (workspace) => {
    if (workspace.status !== AsyncStatus.Succeeded) {
      userModuleStore.setStatus(workspace);
      return;
    }
    if (workspace.result?.connected) {
      userModuleStore.boot(workspace.result.id, ModuleType.User);
    } else {
      router.replace({ name: SelectedTab.Connections });
    }
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(HomeTitleBar),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["project-container", {
        '-wide': sourceBrowserExpanded.value,
        '-narrow': !sourceBrowserExpanded.value,
        'pointer-events-none opacity-50': _unref(workspacesState) === _unref(WorkspacesState).NoWorkspaces,
      }])
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_2, [
            _createVNode(UserButton, {
              modelValue: _unref(userSelected),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(userSelected) ? (userSelected).value = $event : null))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass({
            'pointer-events-none opacity-50': _unref(workspacesState) === _unref(WorkspacesState).NoCurrent,
          })
          }, [
            _createVNode(Textbox, {
              modelValue: projectSearchRef.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((projectSearchRef).value = $event)),
              type: "search",
              class: "padding",
              ref_key: "searchProjectsBox",
              ref: searchProjectsBox
            }, null, 8, ["modelValue"]),
            _createElementVNode("div", {
              class: _normalizeClass(["categories", { '-sb-retracted': !sourceBrowserExpanded.value }])
            }, [
              _cache[7] || (_cache[7] = _createElementVNode("span", { class: "title-line" }, [
                _createElementVNode("div", { class: "title" }, "Projects")
              ], -1)),
              _createVNode(TextButton, {
                "data-test": "new-project",
                label: "New Project",
                icon: "new-project",
                menu: true,
                onClick: newProject,
                class: "new-project-button"
              }),
              _createVNode(MenuListItem, {
                label: "My Projects",
                icon: "claritype-thin",
                selected: showFilter(_unref(ModuleType).User),
                onSelected: _cache[2] || (_cache[2] = ($event: any) => (setModuleType(_unref(ModuleType).User)))
              }, null, 8, ["selected"]),
              (_unref(environment).requireBoolean('READER_VIEW_PUBLISHING_ENABLED'))
                ? (_openBlock(), _createBlock(MenuListItem, {
                    key: 0,
                    label: "Reader Views",
                    icon: "md:bar_chart",
                    selected: showFilter(_unref(ModuleType).Reader),
                    onSelected: _cache[3] || (_cache[3] = ($event: any) => (setModuleType(_unref(ModuleType).Reader)))
                  }, null, 8, ["selected"]))
                : _createCommentVNode("", true),
              (_unref(environment).requireBoolean('ENABLE_PUBLISHING'))
                ? (_openBlock(), _createBlock(MenuListItem, {
                    key: 1,
                    label: "Published Projects",
                    icon: "md:share",
                    selected: showFilter(_unref(ModuleType).Published),
                    onSelected: _cache[4] || (_cache[4] = ($event: any) => (setModuleType(_unref(ModuleType).Published)))
                  }, null, 8, ["selected"]))
                : _createCommentVNode("", true),
              (_unref(environment).requireBoolean('LOCAL_EXPORTS'))
                ? (_openBlock(), _createBlock(MenuListItem, {
                    key: 2,
                    label: "Exported Projects",
                    icon: "md:computer",
                    selected: showFilter(_unref(ModuleType).Local),
                    onSelected: _cache[5] || (_cache[5] = ($event: any) => (setModuleType(_unref(ModuleType).Local)))
                  }, null, 8, ["selected"]))
                : _createCommentVNode("", true)
            ], 2)
          ], 2)
        ]),
        _createElementVNode("div", {
          class: "source-browser-container-container",
          ref_key: "parentDiv",
          ref: parentDiv
        }, [
          _createVNode(SourceBrowser, {
            onSelectTables: sourceBrowserTablesSelected,
            "parent-height": divHeight.value,
            onExpanded: adjustColumnWidth,
            "allow-workspace-switching": true
          }, null, 8, ["parent-height"])
        ], 512)
      ], 2),
      (_unref(subPage) === _unref(SubPage).Modules)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(Modules, {
                modules: modules.value,
                filtered: projectSearchRef.value.length > 0,
                "allow-deletes": allowDeletes.value,
                onOpenModule: openModule,
                onDeleteModule: _cache[6] || (_cache[6] = ($event: any) => (deleteModule($event)))
              }, null, 8, ["modules", "filtered", "allow-deletes"])
            ])
          ]))
        : _createCommentVNode("", true),
      (_unref(subPage) === _unref(SubPage).UserSettings)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(UserPage)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})