import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed, ref } from "vue";
import Icon from "./Icon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MenuListItem',
  props: {
    label: {},
    selected: { type: Boolean },
    icon: {}
  },
  emits: ["selected"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const hovered = ref(false);

const iconColor = computed(() => {
  if (props.selected) {
    return "black";
  }
  if (hovered.value) {
    return "orange";
  }
  return "white";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["category", { selected: _ctx.selected }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('selected'))),
    onMouseover: _cache[1] || (_cache[1] = ($event: any) => (hovered.value = true)),
    onMouseout: _cache[2] || (_cache[2] = ($event: any) => (hovered.value = false))
  }, [
    _createVNode(Icon, {
      name: _ctx.icon,
      color: iconColor.value
    }, null, 8, ["name", "color"]),
    _createElementVNode("p", null, _toDisplayString(_ctx.label), 1)
  ], 34))
}
}

})