import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "auth-loading user-name"
}
const _hoisted_2 = {
  key: 0,
  class: "menu-list-with-icon"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "user-name" }
const _hoisted_5 = {
  key: 1,
  class: "single-user-mode user-name"
}

import { useAuthProvider } from "@/common/auth/authStore";
import Icon from "@/common/components/Icon.vue";
import TextButton from "@/common/components/TextButton.vue";
import { useUserStore } from "@/common/stores/userStore";
import { storeToRefs } from "pinia";
import { computed, onMounted } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeUserButton',
  props: {
    modelValue: { type: Boolean }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const selected = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const auth = useAuthProvider();
const userStore = useUserStore();
const userStoreRefs = storeToRefs(userStore);

const user = computed(() => userStoreRefs.user?.value);

function login() {
  auth.loginWithRedirect();
}

function toggle() {
  selected.value = !selected.value;
}
onMounted(() => {
  userStore.reloadUser();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container", { 'container-selected': selected.value }]),
    onClick: toggle
  }, [
    (_unref(auth).isEnabled)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_unref(auth).isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Loading User…"))
            : (_unref(auth).isAuthenticated)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (user.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("img", {
                          src: user.value.picture,
                          class: "account-circle"
                        }, null, 8, _hoisted_3),
                        _createElementVNode("div", _hoisted_4, _toDisplayString(user.value.name), 1)
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              : (_openBlock(), _createBlock(TextButton, {
                  key: 2,
                  label: "Login",
                  onClick: login
                }))
        ], 64))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(Icon, {
            name: "person",
            color: "white"
          }),
          _cache[0] || (_cache[0] = _createTextVNode(" Single User Mode "))
        ]))
  ], 2))
}
}

})